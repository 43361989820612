import React from "react";
import './Privacy.css';

const PrivacyPolicyEN = () => {
    return (
        <>
            <p className="header">
                Privacy policy
            </p>
            <p className="topic">
                Introduction
            </p>
            <p className="normal">
                With the following data protection declaration, we would like to inform you about the types of your
                personal data (hereinafter also referred to as "data") we process, for what purposes and to what extent
                purposes and to what extent in the conp of providing our application.
                The terms used are not gender-specific.
                Status: December 20, 2020
            </p>
            <p className="topic">
                Table of contents
            </p>
            <p className="normal">
                •   Introduction <br/>
                •   overview of the processing operations<br/>
                •   Relevant legal basis<br/>
                •   Transfer and disclosure of personal data<br/>
                •   Data processing in third countries<br/>
                •   Registration, login and user account<br/>
                •   Single sign-on login<br/>
                •   Web analytics, monitoring and optimization<br/>
                •   Online marketing<br/>
                •   Deletion of data<br/>
                •   Modification and updating of the privacy policy
            </p>
            <p className="topic">
                Processing overview
            </p>
            <p className="normal">
                The following overview summarizes the types of data processed and the purposes of their processing and refers to the data subjects.
            </p>
            <p className="underTopic">
                Types of data processed
            </p>
            <p className="underNormal">
                •	Inventory data (e.g., names, addresses).<br/>
                •	Content data (e.g. entries in online forms).<br/>
                •	Contact data (e.g. email, phone numbers).<br/>
                •   Meta/communication data (e.g. device information, IP addresses).<br/>
                •   Usage data (e.g. web pages visited, interest in content, access times).
            </p>
            <p className="underTopic">
                Categories of persons concerned
            </p>
            <p className="underNormal">
                - Interested parties.<br/>
                - Users (e.g., website visitors, users of online services).
            </p>
            <p className="underTopic">
                Processing purposes
            </p>
            <p className="underNormal">
                - Registration process.<br/>
                - Conversion measurement (measuring the effectiveness of marketing efforts).<br/>
                - Interest-based and behavioral marketing.<br/>
                - Profiling (creating user profiles).<br/>
                - Remarketing.<br/>
                - Reach measurement (e.g. access statistics, recognition of returning visitors).<br/>
                - Security measures.<br/>
                - Tracking (e.g. interest/behavioral profiling, use of cookies).<br/>
                - Provision of contractual services and customer service.<br/>
                - Administration and response to inquiries.
            </p>
            <p className="underTopic">
                Relevant legal bases
            </p>
            <p className="underNormal">
                In the following, we share the legal basis of the General Data Protection Regulation (GDPR) on the basis of which we process personal data. Please note that in addition to the provisions of the GDPR, the national data protection provisions in your or our country of residence and domicile may apply. Furthermore, should more specific legal bases be relevant in individual cases, we will inform you of these in the data protection declaration.
            </p>
            <p className="underNormal">
                <br/>
                - Consent (Art. 6 para. 1 p. 1 lit. a. GDPR) - The data subject has given his/her consent to the processing of personal data concerning him/her for a specific purpose or purposes.
                <br/><br/>
                - Performance of a contract and pre-contractual requests (Art. 6 para. 1 p. 1 lit. b. GDPR) - Processing is necessary for the performance of a contract to which the data subject is a party or for the performance of pre-contractual measures carried out at the data subject's request.
                <br/><br/>
                - Legitimate interests (Art. 6 para. 1 p. 1 lit. f. GDPR) - Processing is necessary for the purposes of the legitimate interests of the controller or a third party, unless such interests are overridden by the interests or fundamental rights and freedoms of the data subject which require the protection of personal data.
                <br/>
            </p>
            <p className="underNormal">
                National data protection regulations in Germany: In addition to the data protection regulations of the General Data Protection Regulation, national regulations on data protection apply in Germany. These include, in particular, the Act on Protection against Misuse of Personal Data in Data Processing (Federal Data Protection Act - BDSG). In particular, the BDSG contains special regulations on the right to information, the right to erasure, the right to object, the processing of special categories of personal data, processing for other purposes and transmission, as well as automated decision-making in individual cases, including profiling. Furthermore, it regulates data processing for employment purposes (Section 26 BDSG), in particular with regard to the establishment, implementation or termination of employment relationships as well as the consent of employees. Furthermore, state data protection laws of the individual federal states may apply.
            </p>
            <p className="topic">
                Transfer and disclosure of personal data
            </p>
            <p className="normal">
                In the course of our processing of personal data, it may happen that the data is transferred to other bodies, companies, legally independent organizational units or persons or that it is disclosed to them. Recipients of this data may include, for example, payment institutions in the conp of payment transactions, service providers commissioned with IT tasks or providers of services and content that are integrated into a website. In such cases, we observe the legal requirements and, in particular, conclude appropriate contracts or agreements that serve to protect your data with the recipients of your data.
            </p>
            <p className="topic">
                Data processing in third countries
            </p>
            <div>
                <p className="normal">
                    If we process data in a third country (i.e., outside the European Union (EU), the European Economic Area (EEA)) or the processing takes place in the conp of the use of third-party services or the disclosure or transfer of data to other persons, entities or companies, this is only done in accordance with the legal requirements.
                    Subject to express consent or contractually or legally required transfer, we process or allow the processing of data only in third countries with a recognized level of data protection, contractual obligation by so-called standard protection clauses of the EU Commission, in the presence of certifications or binding internal data protection regulations (Art. 44 to 49 GDPR, information page of the EU Commission:
                </p>
                <p className="normal">
                    https://ec.europa.eu/info/law/law-topic"/data-protection/international-dimension-data-protection_de
                </p>
            </div>
            <p className="topic">
                Registration, login and user account
            </p>
            <p className="normal">
                Users can create a user account. In the course of registration, users are provided with the required mandatory data and processed for the purpose of providing the user account on the basis of contractual obligation fulfillment. The processed data includes in particular the login information (name, password as well as an e-mail address). The data entered during registration is used for the purposes of using the user account and its purpose.
                Users may be informed by e-mail about events relevant to their user account, such as technical changes. If users have terminated their user account, their data with regard to the user account will be deleted, subject to any legal obligation to retain such data. It is the responsibility of users to back up their data upon termination before the end of the contract. We are entitled to irretrievably delete all user data stored during the term of the contract.
                Within the scope of the use of our registration and login functions as well as the use of the user account, we store the IP address and the time of the respective user action. The storage is based on our legitimate interests as well as those of the users in protection against misuse and other unauthorized use. As a matter of principle, this data is not passed on to third parties unless it is necessary for the prosecution of our claims or there is a legal obligation to do so.
            </p>
            <p className="underNormal">
                <br/>
                - Types of data processed: inventory data (e.g., names, addresses), contact data (e.g., email, phone numbers), content data (e.g., input in online forms), meta/communication data (e.g., device information, IP addresses).
                <br/><br/>
                - Data Subjects: Users (e.g., website visitors, users of online services).
                <br/><br/>
                - Purposes of processing: providing contractual services and customer service, security measures, managing and responding to requests.
                <br/><br/>
                - Legal bases: consent (Art. 6 para. 1 p. 1 lit. a. GDPR), contract performance and pre-contractual requests (Art. 6 para. 1 p. 1 lit. b. GDPR), legitimate interests (Art. 6 para. 1 p. 1 lit. f. GDPR).
                <br/>
            </p>
            <p className="topic">
                Single sign-on login
            </p>
            <p className="normal">
                Single sign-on" or "single sign-on logon or authentication" refers to procedures that allow users to log on to a provider of single sign-on procedures (e.g., a social network), including our online offering, with the aid of a user account. The prerequisite for single sign-on authentication is that users are registered with the respective single sign-on provider and enter the required access data in the online form provided for this purpose, or are already registered with the single sign-on provider and confirm the single sign-on registration via button.
                <br/><br/>
                Authentication takes place directly with the respective single sign-on provider. In the course of such authentication, we receive a user ID with the information that the user is logged in under this user ID at the respective single sign-on provider and an ID that cannot be used by us for other purposes (so-called "user handle"). Whether additional data is transmitted to us depends solely on the single sign-on procedure used, on the data releases selected as part of the authentication process and also on which data users have released in the privacy or other settings of the user account with the single sign-on provider. Depending on the single sign-on provider and the user's choice, this can be different data, usually the e-mail address and the user name. The password entered as part of the single sign-on process with the single sign-on provider is neither visible to us, nor is it stored by us.
                <br/><br/>
                Users are asked to note that their details stored with us may be automatically matched with their user account with the single sign-on provider, but that this is not always possible or actually occurs. If, for example, users' email addresses change, they must change them manually in their user account with us.
                <br/><br/>
                We may use single sign-on login, if agreed with users, as part of or prior to the performance of the contract, if users have been asked to do so, process it as part of consent, and otherwise use it based on the legitimate interests of us and the interests of users in an effective and secure login system.
                <br/><br/>
                Should users ever decide that they no longer wish to use the link of their user account with the single sign-on provider for the single sign-on procedure, they must remove this link within their user account with the single sign-on provider. If users wish to delete their data from us, they must cancel their registration with us.

            </p>
            <p className="underNormal">
                <br/>
                - Types of data processed: inventory data (e.g. names, addresses), contact data (e.g. e-mail, telephone numbers).<br/><br/>
                - Data subjects: Users (e.g., website visitors, users of online services).<br/><br/>
                - Purposes of processing: provision of contractual services and customer service, registration procedure.<br/><br/>
                - Legal grounds: consent (Art. 6 para. 1 p. 1 lit. a. GDPR), contract performance and pre-contractual requests (Art. 6 para. 1 p. 1 lit. b. GDPR), legitimate interests (Art. 6 para. 1 p. 1 lit. f. GDPR).
                <br/>
            </p>
            <p className="underTopic">
                Services used and service providers:
            </p>
            <div>
                <p className="underNormal">
                    - Google Single-Sign-On: Authentication service; Service provider: Google Ireland Limited, Gordon House, Barrow Street, Dublin 4, Ireland; parent company: Google LLC, 1600 Amphitheatre Parkway, Mountain div, CA 94043, USA; website: https://www.google.de; privacy policy: https://policies.google.com/privacy; opt-out: settings for the display of advertising:
                </p>
                <p className="underNormal">
                    https://adssettings.google.com/authenticated
                </p>
            </div>
            <p className="topic">
                Web analysis, monitoring and optimization
            </p>
            <p className="normal">
                Web analytics (also referred to as "reach measurement") is used to evaluate the flow of visitors to our online offering and may include behavior, interests or demographic information about visitors, such as age or gender, as pseudonymous values. With the help of reach analysis, we can recognize, for example, at what time our online offer or its functions or content are most frequently used or invite re-use. Likewise, we can understand which areas need optimization.
                <br/><br/>
                In addition to web analytics, we may also use testing procedures, for example, to test and optimize different versions of our online offering or its components.
                <br/><br/>
                For these purposes, so-called user profiles may be created and stored in a file (so-called "cookie") or similar procedures may be used with the same purpose. This information may include, for example, content dived, websites visited and elements used there, and technical information such as the browser used, the computer system used, and information on usage times. If users have consented to the collection of their location data, this may also be processed, depending on the provider.
                <br/><br/>
                The IP addresses of users are also stored. However, we use an IP masking procedure (i.e., pseudonymization by shortening the IP address) to protect users. Generally, in the conp of web analysis, A/B testing and optimization, no clear data of the users (such as e-mail addresses or names) are stored, but pseudonyms. This means that we, as well as the providers of the software used, do not know the actual identity of the users, but only the information stored in their profiles for the purposes of the respective procedures.
                <br/><br/>
                Notes on legal bases: if we ask users for their consent to use the third-party providers, the legal basis for processing data is consent. Otherwise, users' data is processed on the basis of our legitimate interests (i.e. interest in efficient, economic and recipient-friendly services). In this conp, we would also like to refer you to the information on the use of cookies in this privacy policy.

            </p>
            <p className="underNormal">
                <br/>
                - Types of data processed: Usage data (e.g. web pages visited, interest in content, access times).
                <br/><br/>
                - Data subjects: Users (e.g., website visitors, users of online services).
                <br/><br/>
                - Purposes of processing: reach measurement (e.g. access statistics, recognition of returning visitors), tracking (e.g. interest/behavior-based profiling, use of cookies), conversion measurement (measurement of the effectiveness of marketing measures), profiling (creation of user profiles), interest-based and behavior-based marketing.
                <br/><br/>
                - Security measures: IP masking (pseudonymization of the IP address).
                <br/><br/>
                - Legal basis: Consent (Art. 6 para. 1 p. 1 lit. a. GDPR), Legitimate interests (Art. 6 para. 1 p. 1 lit. f. GDPR).
            </p>
            <p className="underTopic">
                Services used and service providers:
            </p>
            <div>
                <p className="underNormal">
                    - Firebase: Use of Google Analytics data for purposes of improving areas of our online offering and better aligning our marketing efforts with potential user interests; service provider: Google Ireland Limited, Gordon House, Barrow Street, Dublin 4, Ireland, parent company: Google LLC, 1600 Amphitheatre Parkway, Mountain div, CA 94043, USA; Website: https://firebase.google.com/; Privacy policy: https://policies.google.com/privacy; Ad display settings: https://adssettings.google.com/authenticated.
                </p>
                <p className="underNormal">Links:</p>
                <p className="underNormal">https://firebase.google.com/</p>
                <p className="underNormal">https://policies.google.com/privacy</p>
                <p className="underNormal">https://adssettings.google.com/authenticated</p>
            </div>
            <p className="topic">
                Onlinemarketing
            </p>
            <p className="normal">
                We process personal data for online marketing purposes, which may include, in particular, marketing advertising space or displaying promotional and other content (collectively, "Content") based on users' potential interests and measuring its effectiveness.
                <br/><br/>
                For these purposes, so-called user profiles are created and stored in a file (so-called "cookie") or similar procedures are used, by means of which the information about the user relevant for the presentation of the aforementioned content is stored. This information may include, for example, content dived, websites visited, online networks used, but also communication partners and technical information such as the browser used, the computer system used and information on usage times. If users have consented to the collection of their location data, this may also be processed.
                <br/><br/>
                The IP addresses of users are also stored. However, we use available IP masking procedures (i.e., pseudonymization by shortening the IP address) to protect users. In general, no clear user data (such as e-mail addresses or names) is stored as part of the online marketing process, but pseudonyms. This means that we, as well as the providers of the online marketing procedures, do not know the actual identity of the users, but only the information stored in their profiles.
                <br/><br/>
                The information in the profiles is usually stored in the cookies or by means of similar procedures. These cookies can later generally be read on other websites that use the same online marketing method and analyzed for the purpose of displaying content as well as supplemented with further data and stored on the server of the online marketing method provider.
                <br/><br/>
                Exceptionally, clear data can be assigned to profiles. This is the case if, for example, the users are members of a social network whose online marketing procedure we use and the network links the users' profiles to the aforementioned data. We ask to note that users may make additional arrangements with the providers, e.g. by giving consent as part of the registration process.
                <br/><br/>
                In principle, we only receive access to summarized information about the success of our advertisements. However, in the conp of so-called conversion measurements, we can check which of our online marketing processes have led to a so-called conversion, i.e., for example, to a contract being concluded with us. The conversion measurement is used solely to analyze the success of our marketing measures.
                <br/><br/>
                Unless otherwise stated, we ask you to assume that cookies used will be stored for a period of two years.
                <br/><br/>
                Notes on legal bases: if we ask users for their consent to use third-party providers, the legal basis for processing data is consent. Otherwise, users' data is processed on the basis of our legitimate interests (i.e. interest in efficient, economic and recipient-friendly services). In this conp, we would also like to refer you to the information on the use of cookies in this privacy policy.

            </p>
            <div>
                <p className="underNormal">
                    <br/>
                    - Types of data processed: Usage data (e.g. web pages visited, interest in content, access times), meta/communication data (e.g. device information, IP addresses).
                    <br/><br/>
                    - Data Subjects: Users (e.g., website visitors, users of online services), prospective customers.
                    <br/><br/>
                    - Purposes of processing: tracking (e.g. interest/behavioral profiling, use of cookies), remarketing, conversion measurement (measurement of the effectiveness of marketing measures), interest-based and behavioral marketing, profiling (creation of user profiles), reach measurement (e.g. access statistics, recognition of returning visitors).
                    <br/><br/>
                    - Security measures: IP masking (pseudonymization of the IP address).
                    <br/><br/>
                    - Legal basis: Consent (Art. 6 para. 1 p. 1 lit. a. GDPR), Legitimate interests (Art. 6 para. 1 p. 1 lit. f. GDPR).
                    <br/><br/>
                    - Possibility of objection (opt-out): We refer to the data protection notices of the respective providers and the objection options given to the providers (so-called "opt-out"). If no explicit opt-out option has been specified, you have the option of disabling cookies in your browser settings. However, this may restrict functions of our online offer. We therefore recommend the following additional opt-out options, which are offered in summary for the respective areas:

                </p>
                <p className="underNormal">a) Europe: https://www.youronlinechoices.eu.</p>
                <p className="underNormal">b) Canada: https://www.youradchoices.ca/choices</p>
                <p className="underNormal">c) USA: https://www.aboutads.info/choices</p>
                <p className="underNormal">d) Inter-area: https://optout.aboutads.info</p>

            </div>
            <p className="underTopic">
                Services used and service providers:
            </p>
            <div>
                <p className="underNormal">
                    <br/>
                    - Google Analytics: Onlinemarketing und Webanalyse; Dienstanbieter: Google Ireland Limited, Gordon House, Barrow Street, Dublin 4, Irland, Mutterunternehmen: Google LLC, 1600 Amphitheatre Parkway, Mountain div, CA 94043, USA; </p>
                <p className="underNormal">Website: https://marketingplatform.google.com/intl/de/about/analytics/; </p>
                <p className="underNormal">Privacy policy: https://policies.google.com/privacy; </p>
                <p className="underNormal">Possibility of objection (Opt-Out): Opt-Out-Plugin: https://tools.google.com/dlpage/gaoptout?hl=de, </p>
                <p className="underNormal">Settings for the display of advertisements: https://adssettings.google.com/authenticated</p>

                <p className="underNormal">
                    <br/>
                    - Google Ads and conversion measurement: We use the online marketing method "Google Ads" to place ads in the Google advertising network (e.g., in search results, in videos, on web pages, etc.) so that they are displayed to users who have a presumed interest in the ads. Furthermore, we measure the conversion of the ads. However, we only learn the anonymous total number of users who clicked on our ad and were redirected to a page tagged with a so-called "conversion tracking tag". However, we ourselves do not receive any information that can be used to identify users. Service provider: Google Ireland Limited, Gordon House, Barrow Street, Dublin 4, Ireland, parent company: Google LLC, 1600 Amphitheatre Parkway, Mountain div, CA 94043, USA;
                </p>
                <p className="underNormal">Website: https://marketingplatform.google.com; </p>
                <p className="underNormal">Privacy policy: https://policies.google.com/privacy; </p>
            </div>
            <p className="topic">
                Cloud Service
            </p>
            <p className="normal">
                We use software services accessible via the Internet and running on the servers of their providers (so-called "cloud services", also referred to as "software as a service") for the following purposes: document storage and management, calendar management, emailing, spreadsheets and presentations, sharing documents, content and information with specific recipients or publishing web pages, forms or other content and information, as well as chats and participation in audio and video conferences.
                <br/><br/>
                In this conp, personal data may be processed and stored on the servers of the providers to the extent that these are part of communication processes with us or are otherwise processed by us as set out in the conp of this privacy policy. This data may include, in particular, master data and contact data of users, data on transactions, contracts, other processes and their contents.

            </p>
            <p className="underTopic">
                Services used and service providers:
            </p>
            <div>
                <p className="underNormal">
                    <br/>
                    - Firebase: Backend und Serverhosting; Dienstanbieter: Google Ireland Limited, Gordon House, Barrow Street, Dublin 4, Irland, Mutterunternehmen: Google LLC, 1600 Amphitheatre Parkway, Mountain div, CA 94043, USA;
                </p>
                <p className="underNormal">Website: https://firebase.google.com/; </p>
                <p className="underNormal">Privacy policy: https://policies.google.com/privacy;</p>
            </div>
            <p className="topic">
                Data deletion
            </p>
            <p className="normal">
                The data processed by us will be deleted in accordance with the legal requirements as soon as their consents permitted for processing are revoked or other permissions cease to apply (e.g. if the purpose of processing this data has ceased to apply or it is not required for the purpose).
                <br/><br/>
                Unless the data is deleted because it is required for other and legally permissible purposes, its processing will be limited to these purposes. That is, the data is blocked and not processed for other purposes. This applies, for example, to data that must be retained for reasons of commercial or tax law or whose storage is necessary for the assertion, exercise or defense of legal claims or for the protection of the rights of another natural or legal person.
                <br/><br/>
                Further information on the deletion of personal data can also be found in the individual data protection notices of this privacy policy.
            </p>
            <p className="topic">
                Modification and update of the privacy policy
            </p>
            <p className="normal">
                We ask you to regularly inform yourself about the content of our privacy policy. We adapt the data protection declaration as soon as the changes in the data processing carried out by us make this necessary. We will inform you as soon as the changes require an act of cooperation on your part (e.g. consent) or other individual notification.
                <br/><br/>
                If we provide addresses and contact information of companies and organizations in this privacy statement, please note that the addresses may change over time and please check the information before contacting us.
            </p>
        </>
    );

}
export default PrivacyPolicyEN;
