import React from "react";

//<a href="#">Privacy</a> &middot; <a href="#">Terms</a>
const FooterHome = () => (
    <footer className="container">
        <p className="float-end"><a href="#">Back to top</a></p>
        <p>&copy; 2021 upGrades &middot; <a href="/impressum">Impressum</a></p>
    </footer>
)

const FooterDef = () => (
    <footer style={{position: "fixed", height: "5%", bottom: 0, width: "15%"}}>
        <a className="btn btn-link" style={{position: "fixed", right: 0, bottom: 0, fontSize: "0.8em"}}
           href="/impressum">Impressum</a>
    </footer>
)

export default {FooterDef, FooterHome};
