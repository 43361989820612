import React from 'react';
import {
    BrowserRouter as Router,
    Route,
} from "react-router-dom";
import './App.css';
import Header from "./components/Header";
import Homepage from "./components/Homepage";
import Impressum from "./components/Impressum";
import AccountCreation from "./components/account/AccountCreation";
import AccountOverview from "./components/account/AccountOverview";
import FooterComp from "./components/Footer";
import Privacy from "./components/Privacy";
import PrivacyPolicyEN from "./components/data/PrivacyPolicyEN";

//              <Route path="/" exact component={Homepage}/>
function App() {
  return (
      <>
          <Router>
              <Route path="/" component={Header}/>
              <Route path="/" exact component={Homepage}/>
              <Route path="/impressum" exact component={Impressum}/>
              <Route path="/privacy-en" exact component={PrivacyPolicyEN}/>
              <Route path="/privacy-de" exact component={PrivacyPolicyEN}/>
              <Route path="/privacy" exact component={Privacy}/>
              <Route path="/grades" exact component={AccountOverview}/>
              <Route path="/" component={FooterComp.FooterDef}/>
              <Route path="/" exact component={FooterComp.FooterHome}/>
          </Router>
      </>
  );
}

export default App;
