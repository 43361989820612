import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import Firebase from "firebase/app";

const firebaseConfig = {
    apiKey: "AIzaSyCu_Q4LTxJy9KgzV8kD8Ot49q1D-aygYAo",
    authDomain: "notenmanager-efe34.firebaseapp.com",
    databaseURL: "https://notenmanager-efe34.firebaseio.com",
    projectId: "notenmanager-efe34",
    storageBucket: "notenmanager-efe34.appspot.com",
    messagingSenderId: "547925949359",
    appId: "1:1046873095312:android:f2b86024f1c2f2ddde2c15",
    measurementId: "G-243518840"
};

Firebase.initializeApp(firebaseConfig);

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
