import React from "react";
import Firebase from "firebase";

export interface Subject {
    name: string;
    color: string;
    grades: Grade[];
}

export interface Grade {
    grade: number;
    topic: string;
    year: string;
    art: string;
    date: string;
    weight: number;
    id: number;
}

async function getSubjectList(userid?: string): Promise<Subject[]> {
    if(userid) {
        const snap = await Firebase.firestore().collection("UserSaves").doc(userid).get()
        if(snap && snap.data()) {
            // @ts-ignore
            return snap.data().grades as Subject[];
        }
    }
    return [];
}

function rateGrade(grade: number) {
    switch (grade) {
        case 15:
            return "#07ff00"
        case 14:
            return "#56ff43"
        case 13:
            return "#7eff4f"
        case 12:
            return "#98ff4f"
        case 11:
            return "#bcff4f"
        case 10:
            return "#dfff4f"
        case 9:
            return "#fff64f"
        case 8:
            return "#ffd94f"
        case 7:
            return "#ffbb4f"
        case 6:
            return "#ffa14f"
        case 5:
            return "#ff784f"
        default:
            return "#ff644f";

    }
}

export default {getSubjectList, rateGrade};
