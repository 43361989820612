import React from "react";

const Privacy = () => {

    const Text = (props: { children: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal | null | undefined; }) => <p>{props.children}{"\n"}</p>

    return (
        <div className="container">
            <Text>
                Datenschutzerklärung
            </Text>
            <Text>
                Einleitung
            </Text>
            <Text>
                Mit der folgenden Datenschutzerklärung möchten wir Sie darüber aufklären, welche Arten Ihrer
                personenbezogenen Daten (nachfolgend auch kurz als "Daten“ bezeichnet) wir zu welchen Zwecken und in
                welchem Umfang im Rahmen der Bereitstellung unserer Applikation verarbeiten.
                Die verwendeten Begriffe sind nicht geschlechtsspezifisch.
                Stand: 20. Dezember 2020
            </Text>
            <Text>
                Inhaltsübersicht
            </Text>
        </div>
    )
}

export default Privacy;
