import React, {useState} from "react";
import Firebase from "firebase";
import AccountGradeManager, {Subject} from "./AccountGradeManager";

const AccountOverview = () => {

    const [emailAdress, setEmailAdress] = useState("");
    const [password, setPassword] = useState("");
    const [loginStatus, setLoginStatus] = useState<boolean>(() => Firebase.auth().currentUser?.uid !== undefined)

    const [subjectList, setSubjectList] = useState<Subject[]>([])

    const [refreshDisabled, setRefreshDisabled] = useState(false);

    if(Firebase.auth().currentUser?.uid && !loginStatus) {
        setLoginStatus(true)
    }

    React.useEffect(() => {
        return Firebase.auth().onAuthStateChanged(user => {
            if(!loginStatus && user?.uid)
                setLoginStatus(true)
        })
    }, [])

    React.useEffect(() => {
        if(loginStatus)
            AccountGradeManager.getSubjectList(Firebase.auth().currentUser?.uid).then(list => setSubjectList(list))
    }, [loginStatus]);

    const loginScreen = (
        <>
            <h1 className="text-center display-3" style={{marginTop: 150, marginBottom: 25}}>Anmelden</h1>
            <div className="form-signin">
                <img className="mb-4" src="./Logo_G_Transparent.png" alt="" width="100%" height="100%" style={{marginTop: -65}}/>

                <h1 className="h3 mb-3 fw-normal" style={{marginTop: -65}}>Login</h1>

                <label htmlFor="inputEmail" className="visually-hidden">E-Mail Adresse</label>
                <input onChange={txt => setEmailAdress(txt.target.value)} type="email" id="inputEmail" className="form-control" placeholder="E-Mail Adresse" required autoFocus/>

                <label htmlFor="inputPassword" className="visually-hidden">Passwort</label>
                <input onChange={txt => setPassword(txt.target.value)} type="password" id="inputPassword" className="form-control" placeholder="Passwort" required/>

                {/*<div className="checkbox mb-3">
                    <label>
                        <input type="checkbox" value="remember-me"/>
                    </label>
                </div>*/}

                <button className="w-100 btn btn-lg btn-primary" onClick={signIn}>Einloggen</button>
                <small>Keine finale Online-Notenlisten Version!</small>
                <p className="mt-5 mb-3 text-muted">&copy; 2021</p>
            </div>
        </>)

    const renderSubjects = subjectList.map(v =>
        <div style={{width: "65%", margin: "auto", marginTop: 10}}>
            <div style={{margin: "auto", width: "50%", borderBottom: "2px #aaa solid"}}>
                <h2 style={{color: v.color, textAlign: "center"}}>{v.name}</h2>
            </div>
            <div style={{marginBottom: 120, marginTop: 30}}>
                {v.grades.map(g =>
                    <div style={{flexDirection: "row", display: "flex", justifyContent: "space-between", marginTop: 1, borderBottom: "1px #555 solid"}}>
                        <div style={{color: "#efeaf1", width: "33%"}}>{g.topic}</div>
                        <b style={{color: AccountGradeManager.rateGrade(g.grade), width: "15%"}}>{g.grade}</b>
                        <div style={{color: "#efeaf1", width: "33%", textAlign: "right"}}>{g.art}</div>
                        <div style={{color: "#efeaf1", width: "33%", textAlign: "right"}}>{g.date}</div>
                        <div style={{color: "#efeaf1", width: "33%", textAlign: "right"}}>{g.year}</div>
                    </div>
                )}
            </div>
        </div>)

    const gradesList = (
        <>
            <h1 className="text-center display-3" style={{marginTop: 0, marginBottom: 25, color: "#efeaf1"}}>Notenliste</h1>
            <h6 className="text-center" style={{marginTop: -17.5, marginBottom: 25, color: "#ff6f6f"}}>Achtung: Vorläufige Version</h6>
            <button className="btn btn-danger" style={{marginBottom: 15, marginRight: 15}} onClick={signOut}>Ausloggen</button>
            <button className="btn btn-outline-primary" style={{marginBottom: 15}} onClick={refresh} disabled={refreshDisabled}>Aktualisieren</button>
            <div>{renderSubjects}</div>
        </>
    )

    return (
        <div style={{flex: 1, backgroundColor: loginStatus ? "#1d1d1d":undefined, paddingTop: 100, paddingBottom: 800}}>
            <div className="container">
                {loginStatus ? gradesList:loginScreen}
            </div>
        </div>
    )

    async function refresh() {
        setSubjectList(await AccountGradeManager.getSubjectList(Firebase.auth().currentUser?.uid));
        setRefreshDisabled(true)
        setTimeout(() => setRefreshDisabled(false), 2500)
    }

    function signIn() {
        Firebase.auth().signInWithEmailAndPassword(emailAdress, password).then(user => {
            if(user.user?.uid) {
                setLoginStatus(true)
                setEmailAdress("")
                setPassword("")
            }
            else {
                alert("🤔")
            }
        }).catch(err => alert(err))
    }

    async function signOut() {
        await Firebase.auth().signOut();
        setEmailAdress("")
        setPassword("")
        setLoginStatus(false)
        setSubjectList([])
    }

}

export default AccountOverview;
