import React from "react";
import strings from "./data/Localization";

const Homepage = () => {
    const lang = /*navigator.language.split("-")[0].toLowerCase()*/ "de";
    const localStrings = /*lang.includes("de") ?*/ strings.de/*:strings.default*/;
    return (
        <main>
            <div id="myCarousel" className="carousel slide" data-bs-ride="carousel">
                <div className="carousel-indicators">
                    <button type="button" data-bs-target="#myCarousel" data-bs-slide-to="0" className="active"
                            aria-current="true" aria-label="Slide 1"></button>
                    <button type="button" data-bs-target="#myCarousel" data-bs-slide-to="1" aria-label="Slide 2"></button>
                    <button type="button" data-bs-target="#myCarousel" data-bs-slide-to="2" aria-label="Slide 3"></button>
                </div>
                <div className="carousel-inner">
                    <div className="carousel-item active">
                        <svg className="bd-placeholder-img" width="100%" height="100%" xmlns="http://www.w3.org/2000/svg"
                             aria-hidden="true" preserveAspectRatio="xMidYMid slice" focusable="false">
                            <defs>
                                <linearGradient id="grad1" x1="0%" y1="0%" x2="100%" y2="0%">
                                    <stop offset="0%" style={{stopColor: "#056bfb", stopOpacity:1}} />
                                    <stop offset="100%" style={{stopColor: "#ff5555", stopOpacity:1}} />
                                </linearGradient>
                            </defs>
                            <rect width="100%" height="100%" fill="url(#grad1)"/>
                        </svg>

                        <div className="container">
                            <div className="carousel-caption text-start">
                                {localStrings.home.carousell.p1}
                                <p>
                                    <a className="btn btn-lg btn-outline-light" href="https://play.google.com/store/apps/details?id=de.package.grademanager">Android</a>
                                    <a style={{marginLeft: 15}} className="btn btn-lg btn-outline-light" href="https://apps.apple.com/us/app/upgrades-your-school-grades/id1574226716">iOS</a>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="carousel-item">
                        <svg className="bd-placeholder-img" width="100%" height="100%" xmlns="http://www.w3.org/2000/svg"
                             aria-hidden="true" preserveAspectRatio="xMidYMid slice" focusable="false">
                            <defs>
                                <linearGradient id="grad2" x1="0%" y1="0%" x2="100%" y2="0%">
                                    <stop offset="0%" style={{stopColor: "#ff5555", stopOpacity:1}} />
                                    <stop offset="100%" style={{stopColor: "#1d1d1d", stopOpacity:1}} />
                                </linearGradient>
                            </defs>
                            <rect width="100%" height="100%" fill="url(#grad2)"/>
                        </svg>

                        <div className="container">
                            <div className="carousel-caption">
                                {localStrings.home.carousell.p2}
                            </div>
                        </div>
                    </div>
                    <div className="carousel-item">
                        <svg className="bd-placeholder-img" width="100%" height="100%" xmlns="http://www.w3.org/2000/svg"
                             aria-hidden="true" preserveAspectRatio="xMidYMid slice" focusable="false">
                            <defs>
                                <linearGradient id="grad3" x1="0%" y1="0%" x2="100%" y2="0%">
                                    <stop offset="0%" style={{stopColor: "#1d1d1d", stopOpacity:1}} />
                                    <stop offset="100%" style={{stopColor: "#056bfb", stopOpacity:1}} />
                                </linearGradient>
                            </defs>
                            <rect width="100%" height="100%" fill="url(#grad3)"/>
                        </svg>

                        <div className="container">
                            <div className="carousel-caption text-end">
                                {localStrings.home.carousell.p3}
                                <p>
                                    <a className="btn btn-lg btn-outline-light" href="https://play.google.com/store/apps/details?id=de.package.grademanager">Android</a>
                                    <a style={{marginLeft: 15}} className="btn btn-lg btn-outline-light" href="https://apps.apple.com/us/app/upgrades-your-school-grades/id1574226716">iOS</a>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <button className="carousel-control-prev" type="button" data-bs-target="#myCarousel" data-bs-slide="prev">
                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Previous</span>
                </button>
                <button className="carousel-control-next" type="button" data-bs-target="#myCarousel" data-bs-slide="next">
                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Next</span>
                </button>
            </div>

            <div className="container marketing">

                <div className="row featurette">
                    <div className="col-md-7">
                        {localStrings.home.descriptions.p2}
                    </div>
                    <div className="col-md-5">
                        <img src={lang.includes("de") ? "f2.png":"f2e.png"} width="65%" style={{marginLeft: 150}}/>
                    </div>
                </div>

                <hr className="featurette-divider"/>

                <div className="row featurette">
                    <div className="col-md-7 order-md-2">
                        {localStrings.home.descriptions.p3}
                    </div>
                    <div className="col-md-5 order-md-1">
                        <img src={lang.includes("de") ? "f3.png":"f3e.png"} width="65%" style={{marginRight: 150}}/>
                    </div>
                </div>

                <hr className="featurette-divider"/>

                <div className="row featurette">
                    <div className="col-md-7">
                        {localStrings.home.descriptions.p1}
                    </div>
                    <div className="col-md-5">
                        <img src={lang.includes("de") ? "f1.png":"f1e.png"} width="65%" style={{marginLeft: 150}}/>
                    </div>
                </div>

                <hr className="featurette-divider"/>

                <div className="row featurette">
                    <div className="col-md-7 order-md-2">
                        {localStrings.home.descriptions.p4}
                    </div>
                    <div className="col-md-5 order-md-1">
                        <img src={lang.includes("de") ? "f4.png":"f4e.png"} width="65%" style={{marginRight: 150}}/>
                    </div>
                </div>

                <hr className="featurette-divider"/>

                <div className="row featurette">
                    <div className="col-md-7">
                        {localStrings.home.descriptions.p5}
                    </div>
                    <div className="col-md-5">
                        <img src={lang.includes("de") ? "f5.png":"f5e.png"} width="65%" style={{marginLeft: 150}}/>
                    </div>
                </div>

                <hr className="featurette-divider"/>

                <div className="row featurette">
                    <div className="col-md-7 order-md-2">
                        {localStrings.home.descriptions.p6}
                    </div>
                    <div className="col-md-5 order-md-1">
                        <img src={lang.includes("de") ? "f6.png":"f6e.png"} width="65%" style={{marginRight: 150}}/>
                    </div>
                </div>

                <hr className="featurette-divider"/>

            </div>
        </main>)

}

export default Homepage;
