import React from "react";
import { useLocation } from 'react-router-dom'

const Header = () =>
    <nav className="navbar fixed-top navbar-dark bg-dark navbar-expand-lg">
        <div className="container-fluid">
            <a className="navbar-brand" href="/">upGrades</a>
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav"
                    aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarNav">
                <ul className="navbar-nav">
                    <li className="nav-item">
                        <a className="nav-link" aria-current="page" href={useLocation().pathname.length <= 1 ? "#":"/"}>Home</a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link disabled" aria-current="page" href={useLocation().pathname.includes("/grades") ? "#":"/grades"}>upGrades Web (Coming soon)</a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" href={"https://play.google.com/store/apps/details?id=de.package.grademanager"}>Android</a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" href={"https://apps.apple.com/us/app/upgrades-your-school-grades/id1574226716"}>iOS</a>
                    </li>
                </ul>
            </div>
        </div>
    </nav>

export default Header;
