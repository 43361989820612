import React from 'react';

export interface Strings {
    default: typeof defaults;
    de: typeof defaults;
}

const defaults = {
    home: {
        carousell: {
            p1: <><h1>Your grades at a glance</h1><p>Manage your grades however you want, give them a date, a type or a weight!</p></>,
            p2: <><h1>Your grades, always and everywhere available, thanks to cloud synchronization</h1><p>Whether smartphone, tablet or web, your grades are secure in our cloud and accessible from anywhere.</p>
                <p><a className="btn btn-lg btn-primary disabled" href="/grades">upGrades Web</a></p></>,
            p3: <><h1>Get it now!</h1><p>Download the app today and keep track of your grades and dates!</p></>
        },
        descriptions: {
            p1: <><h2 className="featurette-heading">Our app offers you not only planning, <span className="text-muted">but also analysis</span></h2>
                <p className="lead">In addition to the grade list, subject overview, grade add screen and a calendar, you will even find graphical evaluations of your grades in the statistics.</p></>,
            p2: <><h2 className="featurette-heading">The grade list, <span className="text-muted">clear and informative</span></h2>
                <p className="lead">Here you have an <b>interactive list</b> with which you can view and edit your entered grades. Not only can you sort and group your grades according to the school years you have selected, you can also view the associated <b>average</b> at a glance.
                    You can also edit your grades here afterwards. The average is shown to you not only for the whole subject, but also for the individual types of grades.</p></>,
            p3: <><h2 className="featurette-heading">Subject overview, <span className="text-muted">all subjects in one place.</span></h2>
                <p className="lead">This handy overview shows either as a list or as tiles individual subjects and the associated pending grades and upcoming dates. Pending means that you have not yet entered a grade for a date you have entered. Also you get in the list view the average of all subjects displayed.</p></>,
            p4: <><h2 className="featurette-heading">Enter grade and dates, <span className="text-muted">just as you like</span></h2>
                <p className="lead">Here you can add a grade or a date. The input screen also takes into account entries you have made in the past. This means that you don't have to enter the subject again every time you want to add a grade, but you can simply select it from a list of your previously entered subjects.
                    This is also true for many other parameters. For dates it is similar, but here you can also add to-dos. You can use these to save exam topics, for example.</p></>,
            p5: <><h2 className="featurette-heading">Calendar, <span className="text-muted">all dates always in one view</span></h2>
                <p className="lead">Your calendar with all entered dates. Just tap on an uncolored field and enter your date as you are used to from the Add tab. Today is colored in gray, dates are colored in red, while dates that are due today are colored in yellow. When you select an appointment, you can easily see the details you have entered, including to-dos.
                    You can also scroll through the months normally using the arrow keys below the calendar. You can also view a list of all your dates here.</p></>,
            p6: <><h2 className="featurette-heading">Statistics, <span className="text-muted">always know how you are doing</span></h2>
                <p className="lead">Here you will find some evaluations such as your best ever entered grade for the selected period. You can also access the graph, where you can draw a graph of your grades by subject, type and years.</p></>,
        }
    }
}

const strings: Strings = {
    default: defaults,
    de: {
        home: {
            carousell: {
                p1: <><h1>Deine Noten auf einen Blick</h1><p>Verwalte Deine Noten wie Du willst, gebe ihnen ein Datum, eine Art oder eine Gewichtung!</p></>,
                p2: <><h1>Deine Noten, immer und überall verfügbar, dank Cloud-Synchronisation</h1><p>Ob Smartphone, Tablet oder Web, deine Noten sind in unserer Cloud sicher und von überall aus abrufbar.</p>
                    <p><a className="btn btn-lg btn-primary" href="/grades">Online-Notenliste</a></p></>,
                p3: <><h1>Jetzt Verfügbar!</h1><p>Lade die App noch heute herunter und behalte den Überblick über Deine Noten und Termine!</p></>
            },
            descriptions: {
                p1: <><h2 className="featurette-heading">Unsere App bietet Dir nicht nur Planung, <span className="text-muted">sondern auch Analyse</span></h2>
                    <p className="lead">Neben Notenliste, Fächerübersicht, Noten-Eingabemaske und einem Kalender findest du in den Statistiken sogar grafische Auswertungen deiner Noten.</p></>,
                p2: <><h2 className="featurette-heading">Die Notenliste, <span className="text-muted">übersichtlich und informativ</span></h2>
                    <p className="lead">Hier hast du eine <b>interaktive Liste</b>, mit der du deine eingetragenen Noten einsehen und bearbeiten kannst. Du kannst deine Noten nicht nur nach von dir ausgewählten Schuljahren sortieren und gruppieren lassen, Du kannst auch auf einen Schlag den zugehörigen <b>Durchschnitt</b> einsehen.
                        Auch kannst Du deine Noten hier nachträglich bearbeiten. Der Durchschnitt wird dir nicht nur für das ganze Fach angezeigt, sondern auch für die einzelnen Arten der Noten.</p></>,
                p3: <><h2 className="featurette-heading">Fächerübersicht, <span className="text-muted">alle Fächer immer im Blick</span></h2>
                    <p className="lead">Diese praktische Übersicht zeigt entweder als Liste oder oder als Kacheln einzelne Fächer und die zugehörigen ausstehenden Noten und anstehenden Termine an. Ausstehend bedeutet, dass Du für einen von Dir eingetragenen Termin noch keine Note eingegeben hast. Auch bekommt man in der Listenansicht den Durchschnitt aller Fächer angezeigt.</p></>,
                p4: <><h2 className="featurette-heading">Noten und Termine eintragen, <span className="text-muted">so wie du willst</span></h2>
                    <p className="lead">Hier kannst du eine Note oder einen Termin hinzufügen. Die Eingebemaske berücksichtigt auch von Dir in der Vergangenheit gemachte Eingaben. Das heißt, dass Du nicht jedes Mal, wenn du eine Note für Deutsch hinzufügen willst, noch einmal das Fach Deutsch eingeben musst, sondern es einfach aus einer Liste deiner bisher eingegebenen Fächer wählen kannst.
                        Das gilt auch für viele andere Parameter. Bei Terminen verhält es sich ähnlich, nur kannst Du hier auch To-Dos hinzufügen. Diese kannst nutzen, um z.B. Klausurthemen einzuspeichern.</p></>,
                p5: <><h2 className="featurette-heading">Kalender, <span className="text-muted">alle Termine immer im Blick</span></h2>
                    <p className="lead">Dein Kalender mit allen eingetragenen Terminen. Tippe einfach auf ein nicht eingefärbtes Feld und trage deinen Termin ein, wie du es vom Eintragen-Reiter gewohnt bist. Der heutige Tag ist grau eingefärbt, Termine sind rötlich eingefärbt, während Termine, die am heutigen Tag sind, gelb eingefärbt sind. Wenn du einen Termin auswählst, kannst du ganz bequem deine von dir eingetragenen Details inklusive To-Dos sehen.
                        Du kannst auch ganz normal mittels der Pfeiltasten unterhalb des Kalenders die Monate durchblättern. Auch kannst Du dir hier eine Liste mit all Deinen Terminen anzeigen lassen.</p></>,
                p6: <><h2 className="featurette-heading">Statistiken, <span className="text-muted">immer wissen wo man steht</span></h2>
                    <p className="lead">Hier findest du neben einigen Auswertungen wie deine z.B. beste jemals eingetragene Note für den gewählten Zeitraum. Auch gelangst du hierüber zum Graphen, wo Du dir nach Fach, Art sowie Jahren einen Graphen über deine Noten zeichnen lassen kannst.</p></>,
            }
        }
    }
}

export default strings;
